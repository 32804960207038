import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, IconButton, Link } from "@mui/material";
import Header from "../../components/headers/Header";
import SubHeader from "../../components/subHeader/SubHeader";
import InfoModal from "../../components/infoModal/InfoModal";
import ErrorModal from "../../components/error/errorModal";
import Instance from "./Instance";
import { fetchAllProjects } from "../../features/slices/projectSlice";
import { fetchAllInstances } from "../../features/slices/allInstances";
import { fetchKba } from "../../features/slices/kbaSlice";
import { useAuthentication } from "../../constants/config/useAuthentication";
import { checkAdminUsers } from "../../features/slices/UserData";
import language from "../../constants/languages/en/translations.json";
import { optionData } from "./ProjectData";
import { View } from "../../constants/icons/Icons";
import "./AllProjects.scss";
import { useMsal } from "@azure/msal-react";

const ProjectsTable = React.lazy(() =>
  import("../../components/projectsTable/ProjectsTable")
);

const useFetchData = (token, dispatch, instanceData) => {
  useEffect(() => {
    if (token) {
      dispatch(fetchAllInstances({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (instanceData?.data && token) {
      const instanceLinks = instanceData.data.map(
        (instance) => instance.instanceLink
      );
      dispatch(fetchAllProjects({ token, instanceLinks }));
    }
  }, [dispatch, token, instanceData]);
};

const useCombinedData = (instanceData, allProjectsData) => {
  const [projectsByInstance, setProjectsByInstance] = useState([]);

  useEffect(() => {
    if (instanceData?.data && allProjectsData) {
      const combinedData = allProjectsData.map((project) => {
        const matchedInstance = instanceData.data.find(
          (instance) => instance.instanceLink === project.source
        );
        return {
          ...project,
          instanceName: matchedInstance?.instanceName || "Unknown Instance",
          instanceUiLink: matchedInstance?.instanceUiLink || "",
        };
      });
      setProjectsByInstance(combinedData);
    }
  }, [instanceData, allProjectsData]);

  return projectsByInstance;
};

const AllProjects = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.jwtToken);
  const storedLanguage = localStorage.getItem("language") ?? "EN";
  const userDetails = useAuthentication();
  const instanceData = useSelector((state) => state.allInstances.data);
  const isInstanceApiError = useSelector(
    (state) => state.allInstances.isInstanceApiError
  );
  const isInstanceApiLoading = useSelector(
    (state) => state.allInstances.isInstanceApiLoading
  );
  const instanceApiErrorMessage = useSelector(
    (state) => state.allInstances.instanceApiErrorMessage
  );
  const allProjectsData = useSelector((state) => state.allProjects.data);
  const isLoading = useSelector((state) => state.allProjects.isLoading);
  const isError = useSelector((state) => state.isError);
  const errorMessage = useSelector((state) => state.errorMessage);
  const userState = useSelector((state) => state.user);
  const photo = userState.photo;

  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    title: "",
    description: "",
    kbaCode: "",
    languageCode: "",
  });
  const [isNavigating, setIsNavigating] = useState(false);

  const kbaID = "1";
  const version = 1;

  useFetchData(token, dispatch, instanceData);

  const projectsByInstance = useCombinedData(instanceData, allProjectsData);

  const filteredData = useMemo(() => {
    if (!searchQuery) return projectsByInstance;
    const query = searchQuery.toLowerCase();
    return projectsByInstance.filter(
      (item) =>
        item.name.toLowerCase().includes(query) ||
        item.clientName.toLowerCase().includes(query) ||
        item.instanceName.toLowerCase().includes(query)
    );
  }, [projectsByInstance, searchQuery]);

  const handleInfoClick = useCallback(
    async (language = storedLanguage) => {
      try {
        const langCode = language.toUpperCase();
        const response = await dispatch(
          fetchKba({ token, langCode, kbaID, version })
        ).unwrap();
        if (response.success) {
          const result = response.data;
          setInfoModalData({
            kbaCode: `KBA #${kbaID}`,
            title: result.name,
            languageCode: result.language,
            description: result.context,
          });
          setIsInfoModalOpen(true);
        }
      } catch (error) {
        console.error("Fetching KBA failed:", error);
        alert(
          "Fetching KBA failed: " + (error.message || "Unknown error occurred")
        );
      }
    },
    [dispatch, token, storedLanguage]
  );

  const handleCloseModal = () => setIsInfoModalOpen(false);
  const handleError = () => navigate("/");

  const handleSearch = useCallback((event) => {
    setSearchQuery(event);
  }, []);

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Project"), showSorting: true },
      { colName: "clientName", label: t("Client"), showSorting: true },
      { colName: "instanceName", label: t("Instance"), showSorting: false },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleModal = () => setIsOpen((prev) => !prev);

  const handleInstanceCancel = () => setIsOpen(false);

  const handleButtonClick = useCallback(
    (row) => {
      const email = userDetails?.userDetails?.username;
      const name = userDetails?.userDetails?.name;
      const userId = userDetails?.userDetails?.localAccountId;
      const projectId = row?.id;
      if (row.instanceUiLink && projectId && token) {
        setIsNavigating(true);
        document.cookie = `authToken=${token}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        document.cookie = `name=${name}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        document.cookie = `email=${email}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        document.cookie = `userId=${userId}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        document.cookie = `photo=${photo}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        let targetUrl = row.instanceUiLink;
        window.location.href = `${targetUrl}/project-home/${projectId}`;
      }
    },
    [token, userDetails, photo]
  );

  const handleOnNext = useCallback(async () => {
    const email = userDetails?.userDetails?.username;
    const name = userDetails?.userDetails?.name;
    const userId = userDetails?.userDetails?.localAccountId;
    if (!email) {
      console.error("User details are not available");
      return;
    }
    try {
      setIsNavigating(true);
      document.cookie = `authToken=${token}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
      document.cookie = `name=${name}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
      document.cookie = `email=${email}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
      document.cookie = `userId=${userId}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
      document.cookie = `photo=${photo}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
      const response = await dispatch(
        checkAdminUsers({ emailId: email, token })
      ).unwrap();
      if (response.data === true) {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          try {
            const tokenResponse = await instance.acquireTokenSilent({
              scopes: ["User.ReadBasic.All"],
              account: accounts[0],
            });
            const accessToken = tokenResponse.accessToken;
            document.cookie = `accessToken=${accessToken}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
            window.location.href = `${process.env.REACT_APP_INSTANCE_BASE_URL}create-new-project`;
          } catch (error) {
            console.error("Error fetching AD users:", error);
          }
        }
      } else {
        alert("You are not authorized to access this page.");
        setIsNavigating(false);
      }
    } catch (error) {
      console.error("Error checking admin access:", error);
      setIsNavigating(false);
    }
  }, [dispatch, instance, photo, userDetails, token]);

  const generateRowKey = (row) => row.id;

  const getCellStyle = (column) => {
    switch (column) {
      case "Action":
        return { textAlign: "right", width: "150px", paddingRight: "7%" };
      case "instanceName":
        return { textAlign: "center", width: "215px", paddingRight: "18%" };
      default:
        return { textAlign: "left", width: "250px", paddingLeft: "2%" };
    }
  };

  const renderTableCell = (column, value, row) => {
    const cellStyle = getCellStyle(column);
    let instanceUrl = row.instanceUiLink;
    if (column === "instanceName") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Link href={instanceUrl}>{row.instanceName}</Link>
        </TableCell>
      );
    }

    if (column === "Action") {
      return (
        <TableCell key={column} style={cellStyle}>
          <IconButton onClick={() => handleButtonClick(row)}>
            <View />
          </IconButton>
        </TableCell>
      );
    }

    return (
      <TableCell key={column} style={cellStyle}>
        {value}
      </TableCell>
    );
  };

  if (isError || isInstanceApiError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage || instanceApiErrorMessage}
        handleButtonClick={handleError}
        deleteButtonLabel={t("Reload")}
      />
    );
  }

  return (
    <div style={{ height: "100vh" }}>
      <Header />
      <div className="all-projects-container">
        {(isLoading || isInstanceApiLoading || isNavigating) && (
          <MotifProgressLoader className="loader" show variant="default" />
        )}
        {isOpen && (
          <Instance
            instanceHeader={t("instanceHeader")}
            instanceNext={t("instanceNext")}
            instanceModalDescription={t("instanceModalDescription")}
            handleInstanceCancel={handleInstanceCancel}
            handleInfoClick={handleInfoClick}
            onNext={handleOnNext}
          />
        )}
        <div className="border-box">
          <SubHeader
            title={t("AllProjects")}
            selectedOption={selectedOption}
            addButtonText={language.NewProjectEN}
            onChangeSearchInput={handleSearch}
            onOptionChange={handleOptionChange}
            defaultSelection={language.DefaultEN}
            onClickNewProject={toggleModal}
            options={optionData}
            disableButton={isLoading || isInstanceApiLoading}
          />
          <Suspense fallback={<MotifProgressLoader show variant="default" />}>
            <ProjectsTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              generateRowKey={generateRowKey}
              getCellStyle={getCellStyle}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
            />
          </Suspense>
        </div>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={handleCloseModal}
        data={infoModalData}
        handleInfoClick={handleInfoClick}
      />
    </div>
  );
};

export default AllProjects;
